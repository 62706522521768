

















import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import Player from '@/components/common/Player.vue'
import HighlightText from '@/components/HighlightText.vue'

const PLAYING_VIDEO_CONTINUE_BUTTON_TEXT_KEY = 'preTest.video.playing.continue_button.text'
const ENDED_VIDEO_CONTINUE_BUTTON_TEXT_KEY = 'preTest.video.ended.continue_button.text'

@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title,
    ProgressLine,
    Player,
    HighlightText
  }
})
export default class PreTestQuestionVideo extends Vue {
  private videoUrl = 'https://res.cloudinary.com/hey-expert/video/upload/v1623258370/BellaHealth/BH_Encoded_2/Quidel/AudioFix/02_Pre-test_Quidel_EN_iwxkvo.mp4'
  private continueButtonTextKey = PLAYING_VIDEO_CONTINUE_BUTTON_TEXT_KEY
  private showHighlight = false
  private highlightTextKey = 'preTest.video.ended.question.text'

  private handleVideoEndEvent () {
    this.continueButtonTextKey = ENDED_VIDEO_CONTINUE_BUTTON_TEXT_KEY
    this.showHighlight = true
  }
}
